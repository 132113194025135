.App {
  text-align: center;
  background-color: black;
}

h1 {
  position: absolute;
  font-size: 70px;
  color: #3b3836;
  padding: 15% 40% 0 3%;
  margin: 1% 1% 0% 0;
  text-align: left;
  font-family: Futura,Trebuchet MS,Arial,sans-serif;
}

h2 {
  position: absolute;
  font-size: 20px;
  color: #3b3836;
  max-width: 60%;
  padding:40vh 0% 500% 3%;
  text-align: left;
  font-family: Futura,Trebuchet MS,Arial,sans-serif;
}

.section-title {
  font-size: 80px;
  color: white;
  font-family: Futura,Trebuchet MS,Arial,sans-serif;
  padding-top: 50px;
}

.section-subtitle {
  font-size: 2vw;
  color: white;
  font-family: Futura,Trebuchet MS,Arial,sans-serif;
}

.page-left {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
}

.page-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.horizontal-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.media {
  width: 100%;
  float: left;

}

.item-text {
  font-size: 15px;
  color: white;
  font-family: Futura,Trebuchet MS,Arial,sans-serif;}

/*.involvement .section-items {*/
  /*margin: 0 10vw 0 10vw;*/
/*}*/

/*.about-me .section-items {*/
  /*margin: 0 10vw 0 10vw;*/
/*}*/

/*.projects .section-items {*/
  /*margin: 0 10vw 0 10vw;*/
/*}*/

.section-items {
  display: flex;
  flex-direction: column;
  margin: 0 15vw 0 15vw;
}

.text {
  font-size: 18px;
  color: white;
  font-family: Futura,Trebuchet MS,Arial,sans-serif;
  position: relative;
}

.item {
  background-color: rgba(204, 204, 204, 0.5);
  margin: 1vw;
  padding: 1vw;
  /*width: 20vw;*/
}

.clickable {
  cursor: pointer;
}

.item:hover{
  background-color: rgba(0, 0, 0, 0.2);
  animation: zoominout 1s 1;
}

.modal {
  position: relative;
  margin:auto;
  padding-top: 1%;
  width:50%;
  height: 600px;
  background: rgba(204, 204, 204,0.8);
  overflow: auto;
  animation: zoomin 1s;
  animation-iteration-count: 1;
}

.modal-children {
  padding: 0 20px 20px 20px;
}

.display-block {
  display: flex;
  flex-direction: column;
}

.display-none {
  display: none;
}

.sections {
  box-shadow: 0 30px 40px rgba(0,0,0,.1);

}

.empty {
  height: 200px;
}

.close-button {
  position: fixed;
  bottom: -50px;
  height:50px;
  width:50%;
  background-color: rgba(204, 204, 204, 0.5);
  border: none;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.2);

}

.slideshow {
  height: 400px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

.button-div {
  position:relative;
}

@keyframes zoomin {
  0% {
    transform: scale(0.5,0.5);
  }
  100% {
    transform: scale(1,1);
  }
}

@keyframes zoominout {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.02,1.02);
  }
  100% {
    transform: scale(1,1);
  }
}

@keyframes zoominoutmore {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.5,1.5);
  }
  100% {
    transform: scale(1,1);
  }
}

.bookable-video {
  clip: rect(0px,60px,200px,0px);
}

blockquote {
  quotes: "\201C""\201D";
  margin: 0;
}

blockquote p:before {
  content: open-quote;
  font-weight: bold;
  font-size:250px;
  color:rgba(204, 204, 204, 0.5);
  font-family:Georgia, "Times New Roman", Times, serif;
  position: absolute;
  left:-7vw;
  bottom:-10vh;
}

blockquote p:after {
  content: close-quote;
  font-weight: bold;
  font-size:250px;
  color:rgba(204, 204, 204, 0.5);
  font-family:Georgia, "Times New Roman", Times, serif;
  position: absolute;
  top: 0vh;
  right:-7vw;
}

quotes-text {
  display: flex;
  justify-content: center;
}


.center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.social-background {
  background-color: rgba(204, 204, 204, 0.5);
  border-radius: 10px;
  width: 400px;
}

.social-icon {
  margin: 5px;
}

.social-icon:hover {
  animation: zoominoutmore 1s 1;
}

.social-image {
  width: 50px;
  padding: 2px;
}