.nav-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: translate(-5%, 0%)
}

.nav-item {
    display: inline;
    margin-left: 2rem;
    color: black;
    font-family: Futura,Trebuchet MS,Arial,sans-serif;
}

.nav-item:hover {
    color: white;
    cursor: pointer;
}

.nav-bar {
    background-color: white;
    top: 0px;
    width: 100%;
    height: 80px;
    z-index: 1000;
    opacity: 0.7;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-title {
    display:flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Futura,Trebuchet MS,Arial,sans-serif;
    font-size: 30px;
    transform: translate(10%, 0%);


}

.icon {
    object-fit: cover;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
    -moz-box-shadow: 4px 4px 6px #ccc;
    -webkit-box-shadow: 4px 8px 6px #ccc;
    box-shadow: 4px 4px 6px #ccc;
}

.name {
    display: none;
}